


import React, { useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Images
import BgImg from "~images/blog/food1.jpg";

const Terms = () => {
    const parallax = useRef(null);

    useEffect(() => {
        if (parallax.current) {
            parallax.current.style.backgroundImage = `url(${parallax.current.getAttribute('data-image')})`;
        }
    }, [parallax]);

    return (
		<React.Fragment>
        <section className="page-title valign parallax" data-image={BgImg} ref={parallax}>
			<div className="overlay"></div>	
			<Container>
				<Row>
					<Col className="col-12 text-center">
						<h1 className="blog-title">TERMS AND CONDITIONS FOR CUSTOMERS </h1>
						<p className="blog-info info">Checkout latest articles</p>

					</Col>
				</Row>
				
			</Container>
		</section>

		<section>
		<Container>
		{/* <Row className="justify-content-center">
					<Col className="col-12 col-md-10 col-lg-6">
						
						<div className="section-title text-center pt-5">
							<h3>EatCo Home Customer Privacy Policy </h3>
						</div>
						
					</Col>
				</Row> */}

				
<Row className="justify-content-center">
					<Col className="col-12 col-md-10 col-lg-10">
						
						
						<div className="section-title1 ">
							<ol>
								<li>Both the Vendor and the User/Buyer must be in compliance with all laws and regulations in the country in which they live while accessing and using the Service. The Vendor must agree to use the Services only in compliance with terms & conditions, applicable law, and in a manner that does not violate legal rights or those of any third party/parties. </li>
								<li>Users/Buyers can access the list of food and beverages of the Kitchen Partners listed on the EatCo App and place orders against the Kitchen Partners through App which the Kitchen Partner may or may not accept at its absolute discretion. </li>
								<li>The EatCo App shall make it clear that it is not either a seller of food or beverages only places an order against the partner kitchen on behalf of the User customer pursuant to the aforesaid contract and facilitate the sale and purchase of food and beverages between the two, under the contract for sale and purchase of food and beverages. </li>
								<li>EatCo App shall not be liable for any actions or omissions by the Kitchen Partners including deficiency in service, wrong delivery or order, quality of food, time is taken to prepare or deliver the order, etc. </li>
								<li>Any violation of the Food Safety and Standards Act, 2006 and applicable rules and regulations made thereunder shall solely be towards the seller/vendors of the food products. </li>
								<li>We shall reserve it’s right that at any time and without prior notice, We can remove, block, or disable access to any Content, for any reason or no reason, considered to be objectionable, in violation of the terms and conditions of this Agreement or otherwise harmful to the Services. </li>
								<li>Delivery periods/Pickup time quoted at the time of ordering are approximate only and may vary depending upon the situation. When the Buyer/User opts for Pickup at the time of placing the Order we shall not be liable in any manner in this regard. </li>
								<li>The Buyer/User shall request to order food and beverages from a Kitchen Partner by clicking on ‘Place Order’ tab on the Kitchen Partner page on the EatCo  App shall constitute an unconditional and irrevocable authorization issued in favor of the App to place online orders for food and beverages against the Kitchen Partners on User’s behalf. </li>
								<li>An order, once placed on behalf of User by the EatCo  App and accepted by a Kitchen Partner constitutes a separate contract of sale of goods under the Sale of Goods Act,1930 and the Consumer Protection Act, 2019 or any successor legislation between the Kitchen Partner and the User, to which the App shall not be a party. </li>
								<li>The User must warrant that while placing an order the details like contact number etc. are accurate and correct. By providing these details, the User expresses his/her acceptance of EatCo ’ terms and conditions and privacy policies. </li>
								<li>The User shall not resell food or beverages purchased via the App. </li>
								<li>The User must acknowledge that any cancellation or attempted or purported cancellation of an Order shall amount to a breach of User’s unconditional and irrevocable authorization in favor of the EatCo  App to place that Order against the Kitchen Partners on User’s behalf (“Authorization Breach”). </li>
								<li>In the event the User commits an Authorization Breach, the User shall be liable to pay the liquidated damages of an amount equivalent to the Order Value. The User must undertake to authorize EatCo  App to deduct or collect the amount payable as liquidating damages through such means as the App may determine in its discretion, including without limitation, by deducting such amount from the payment made towards User’s next order. </li>
								<li>We shall not facilitate replacements/ refunds/ or any other resolution without Kitchen Partner’s permission. All refunds shall be processed in the same manner as they are received, unless refunds have been provided to the User in the form of credits, the refund amount will reflect in User’s account based on respective bank policies. </li>
								<li>Every User shall have access to only one EatCo  account and shall not be permitted to create multiple accounts. On finding multiple accounts of any user, we reserve the right to suspend such multiple accounts without being liable for any compensation. </li>
								<li>Every User shall provide accurate, current and complete information during the registration process and to update such information to keep it accurate, current and complete. </li>
								<li>The EatCo  Platform shall reserve the right to suspend or terminate a User’s Account and access to the Services in the event; </li>
								<ol type="A">
										<li>if any information provided during the registration process or thereafter proves to be inaccurate, not current or incomplete; </li>
										<li>if it is believed that a user’s actions may cause legal liability for the EatCo  Platform or the user; or for both. </li>
										<li>if the user is found to be non- compliant with the Terms of Use. </li>
								</ol> 
								<li>Every user shall be responsible for safeguarding his/her password. The user shall not disclose his/her password to any third party and it will be their sole responsibility for any activities or actions under your EatCo  Account, whether or not you have authorized such activities or actions. Goods and services purchased from the Platform are intended for your personal use and you represent that the same are not for resale or you are not acting as an agent for other parties. A. The user shall be entitled to a refund only if you pre-pay for your order at the time of placing your order on the Platform and only in the event of any of the following circumstances: </li>
								<li>The order packaging has been tampered or damaged at the time of delivery; </li>
								<li>The user cancels the order due to: </li>
								<ol type="A">
										<li>Your delivery location following outside our designated delivery zones; </li>
										<li>Failure to contact you by phone or email at the time of confirming the order booking; or </li>
										<li>Where the user cancels the order at the time of confirmation due to unavailability of the items you ordered for at the time of booking. </li>
								</ol> 
								<li>The decision on refunds shall be at the sole discretion of the EatCo  Platform and shall be final and binding. </li>
								<li>All refund amounts shall be credited to your account within 3-4 business days in accordance with the terms that may be stipulated by the bank which has issued the credit/debit card. </li>
								<li>We shall in no manner be liable in any way for any in-person interactions with representatives or staff of the Kitchen Partners or for the User’s experience at the Kitchen Partners. We in no manner are liable to the User if a Kitchen Partner temporarily or permanently shuts down its operations. </li>
								<li>We shall grant User/Buyer a personal, limited, non-exclusive, and non- transferrable license to access and use the Services only as expressly permitted as per the terms and conditions of the Agreement. The User shall agree not to use the services for any illegal purpose or in any manner inconsistent with the terms and conditions of this Agreement. </li>
					
							</ol>
							
						</div>
						
					</Col>
				</Row>
				
			</Container>
		</section>

		</React.Fragment>
		
		
    );
};

export default Terms;










































