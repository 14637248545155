import React from 'react';

import Layout from "../components/Sections/Layout";
import Loader from "../components/Sections/Loader";
import Header from "../components/Sections/Header";
import Policy from "../components/Terms&Conditions/Terms";
import Footer from "../components/Sections/Footer";
import ToTop from "../components/Sections/ToTop";


const Privacy = () => (

    <Layout 
        pageTitle="EatCo - Privacy-policy" 
        colorSchema="../colors/orange.css"
    >
        <Loader />
        <Header nav="privacy" />        
        <Policy/>
        <Footer />
        <ToTop />
    </Layout>

)

export default Privacy;